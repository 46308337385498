export const international = [
  {
    name: "Chen",
    age: 30,
    location: "China, Shanghai",
  },
  {
    name: "Olivia",
    age: 29,
    location: "Canada, Toronto",
  },
  {
    name: "Aisha",
    age: 22,
    location: "Nigeria, Lagos",
  },
  {
    name: "Lucia",
    age: 28,
    location: "Argentina, Buenos Aires",
  },
  {
    name: "Priya",
    age: 25,
    location: "India, Mumbai",
  },
  {
    name: "Maya",
    age: 27,
    location: "Egypt, Cairo",
  },
  {
    name: "Sophie",
    age: 32,
    location: "Australia, Sydney",
  },
];
