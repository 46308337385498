import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useWindowSize } from "../../hooks";
import { Offer as OfferMobile } from "../TopOffersTiles/Offer";
import { CircularProgressbar } from "../CircularProgressbar";
import { LastUpdate } from "../LastUpdate";
import { Rating } from "../Rating";
import { TrackingLink } from "../TrackingLink";
import { Loader } from "../Loader";
import { offers } from "../../data";
import { Button2 } from "../Buttons";
import { numberFixed } from "../../utils";

import "react-circular-progressbar/dist/styles.css";
import styles from "./TopOffersTable.module.scss";

const OfferDesktop = ({ index, name, logo, description, bonus, rating, link, review }) => {
  const [ratingOffer, setRaringOffer] = useState(0);
  const percentageRating = ratingOffer * 10;
  const isFirst = index === 0 ? styles.First : "";

  useEffect(() => {
    setTimeout(() => {
      setRaringOffer(rating);
    }, 1000);
  }, [rating]);

  return (
    <div className={`${styles.Offer} ${isFirst}`} attrnumber={index + 1}>
      <div className={styles.Col1}>
        <div className={styles.Logo}>
          <a href={link}>
            <img src={logo} alt={name} />
          </a>
        </div>
        <div className={styles.Score}>
          <div className={styles.Score__Number}>{numberFixed(rating / 2)}</div>
          <div className={styles.Score__Component}>
            <Rating rating={rating / 2} />
          </div>
        </div>
      </div>
      <div className={styles.Col2}>
        <div className={styles.Description}>{description}</div>
        <div className={styles.ReadMore}>
          <Link to={review}>Read More</Link>
        </div>
      </div>
      <div className={styles.Col3}>
        <div className={styles.Rating}>
          <CircularProgressbar value={percentageRating} text={numberFixed(rating)} />
        </div>
      </div>
      <div className={styles.Col4}>
        <div className={styles.ButtonVisitSite}>
          <TrackingLink
            href={link}
            className={styles.VisitSite}
            style={{ width: "100%", textAlign: "center" }}
            component={Button2}>
            Visit Site
          </TrackingLink>
        </div>
        <div className={styles.Bonus}>
          <TrackingLink href={link}>{bonus}</TrackingLink>
        </div>
      </div>
    </div>
  );
};

export const TopOffersTable = ({ selected = [] }) => {
  const [loading, setLoading] = useState(true);
  const size = useWindowSize();

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  const selectedOffers =
    selected.length === 0
      ? offers
      : selected.reduce((acc, name) => {
          offers.forEach(site => {
            if (name === site.name) {
              acc.push(site);
            }
          });

          return acc;
        }, []);

  return (
    <div className={styles.TopOffersTable}>
      <LastUpdate className={styles.LastUpdate} />
      {loading ? (
        <Loader />
      ) : (
        selectedOffers.map((offer, index) =>
          size.width <= 768 ? (
            <OfferMobile key={index} index={index} {...offer} />
          ) : (
            <OfferDesktop key={index} index={index} {...offer} />
          ),
        )
      )}
    </div>
  );
};
