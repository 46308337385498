import React from "react";
import { TrackingLink } from "../TrackingLink";

import styles from "./ImageWrapper.module.scss";

export const ImageWrapper = ({ href, src, buttonText }) => (
  <div className={styles.ImageWrapper}>
    <TrackingLink href={href}>
      <img src={src} alt="" className="img-res" />
      <div className={styles.Button}>
        <div className="button-2">
          <span>{buttonText}</span>
        </div>
      </div>
    </TrackingLink>
  </div>
);
