import React from "react";

import { Header, Footer, Main, Loader, ScrollVisitSite } from "../components";
import { Offer } from "../components/TopOffersTiles/Offer";
import { offers } from "../data";

export const ReviewLayout = ({ children, location }) => {
  const offer = offers.find(({ review }) => review === location.pathname);

  return (
    <div className="app">
      <React.Suspense fallback={<Loader />}>
        <Header location={location} />
      </React.Suspense>

      <Main>
        <div className="review__row">
          <div className="review__col-content">
            <React.Suspense fallback={<Loader />}>{children}</React.Suspense>
          </div>
          <div className="review__col-sidebar">
            <Offer {...offer} isBannerFix={true} />
          </div>
        </div>
        <ScrollVisitSite {...offer} />
      </Main>

      <React.Suspense fallback={<Loader />}>
        <Footer />
      </React.Suspense>
    </div>
  );
};
