import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useWindowSize } from "../../hooks";
import { setFavoriteGirlStyle, resetFavoriteGirl } from "../../features";

import "./FavoriteGirlAnimation.scss";

export const FavoriteGirlAnimation = () => {
  const dispatch = useDispatch();
  const { active, image, style } = useSelector(state => state.favoriteGirl);
  const size = useWindowSize();

  const leftPosition = widthScreen => {
    if (widthScreen > 1200) {
      return "calc(50% + 500px)";
    } else if (widthScreen > 990 && widthScreen < 1200) {
      return "calc(50% + 400px)";
    } else if (widthScreen > 768 && widthScreen < 990) {
      return "calc(50% + 300px)";
    } else {
      return "calc(100% - 90px)";
    }
  };

  const finalStyle = {
    width: 30,
    height: 30,
    left: leftPosition(size.width),
    top: 60,
    opacity: 0.3,
  };

  useEffect(() => {
    if (active) {
      setTimeout(() => {
        dispatch(setFavoriteGirlStyle(finalStyle));
      }, 500);

      setTimeout(() => {
        dispatch(resetFavoriteGirl());
      }, 1100);
    }
    // eslint-disable-next-line
  }, [active]);

  return (
    <div className="add-to-favorite" style={{ backgroundImage: `url(${image})`, ...style }} />
  );
};
