import React from "react";

import { TrackingLink } from "../TrackingLink";

import styles from "./ScrollBanner.module.scss";

export const ScrollBanner = ({ nationality = "", href = "" }) => (
  <TrackingLink href={href} className={styles.ScrollBanner}>
    <div className={styles.Row}>
      <div className={styles.Text}>
        Wanna Date Beautiful <br />
        {nationality} Woman?
      </div>
      <div className={styles.ButtonBlock}>
        <div className={styles.Button}>Find Girl</div>
      </div>
    </div>
  </TrackingLink>
);
