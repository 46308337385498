import React from "react";
import { Link } from "react-router-dom";
import { REACT_APP_NAME_2 } from "../../configs";
import { Menu } from "./Menu";
import { Favorite } from "./Favorite";

import styles from "./Header.module.scss";

export const Header = ({ location }) => (
  <div className={styles.Header}>
    <div className="container">
      <div className={styles.Row}>
        <div className={styles.Logo}>
          <Link to="/" className={styles.Logo__Link}>
            <div className={styles.Logo__Image}>
              <img src="/images/logo.png" alt={REACT_APP_NAME_2} />
            </div>
            <div className={styles.Logo__Text}>{REACT_APP_NAME_2}</div>
          </Link>
        </div>
        <div className={styles.Nav}>
          <Menu location={location} />
          <Favorite />
        </div>
      </div>
    </div>
  </div>
);
