import React from "react";
import { Link } from "react-router-dom";
import { Button2 } from "../Buttons";
import { Rating } from "../Rating";
import { TrackingLink } from "../TrackingLink";
import { Hot } from "../../assets/svg";
import { numberFixed } from "../../utils";

import styles from "./TopOffersTiles.module.scss";

export const Offer = ({
  index,
  name,
  logo,
  description,
  bonus,
  rating,
  link,
  review,
  isBannerFix = false,
}) => {
  const isFirst = index === 0 ? styles.First : "";

  return (
    <div
      className={`${styles.Offer} ${isFirst} ${isBannerFix ? styles.BannerFix : ""}`}
      attrnumber={index + 1}>
      {!isBannerFix && isFirst && (
        <div className={styles.BestChoice}>
          <Hot width="20" height="20" /> Best Choice
        </div>
      )}
      <div className={styles.Offer__Logo}>
        <TrackingLink href={link}>
          <img src={logo} alt={name} />
        </TrackingLink>
      </div>
      <div className={styles.Offer__Rating}>
        <div className={styles.Offer__Rating__Col}>
          <div className={styles.Offer__Rating__Rating}>
            <div className={styles.Offer__Rating__Rating__Number}>{rating / 2}</div>
            <div className={styles.Offer__Rating__Rating__Component}>
              <Rating rating={rating / 2} />
            </div>
          </div>
        </div>
        <div className={styles.Offer__Rating__Col}>
          <div className={styles.Offer__Rating__Score}>
            <div className={styles.Offer__Rating__Score__Number}>{numberFixed(rating)}</div>
            <div className={styles.Offer__Rating__Score__ReadReview}>
              <Link to={review}>Read Review</Link>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.Offer__Description}>{description}</div>
      <div className={styles.Offer__VisitSite}>
        <TrackingLink href={link} className={styles.Offer__Button} component={Button2}>
          Visit Site
        </TrackingLink>
      </div>
      <div className={styles.Offer__Bonus}>
        <TrackingLink href={link}>{bonus}</TrackingLink>
      </div>
    </div>
  );
};
