import React from "react";
import { REACT_APP_NAME } from "../../configs";

import styles from "./Footer.module.scss";

export const Footer = ({ withPadding = false }) => (
  <div className={`${styles.Footer} ${withPadding === true ? styles.WithPadding : ""}`}>
    <div className="container">
      <div className={styles.Copyright}>© 2023 {REACT_APP_NAME}. All Rights Reserved.</div>
    </div>
  </div>
);
