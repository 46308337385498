import React from "react";

import Swiper from "react-id-swiper";
import "swiper/swiper.scss";
import { Slide } from "./Slide";

export const Courousel = ({ slides = [] }) => {
  const params = {
    spaceBetween: 30,
    centeredSlides: true,
    loop: true,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    breakpoints: {
      1600: {
        slidesPerView: 5,
      },
      1200: {
        slidesPerView: 4,
      },
      990: {
        slidesPerView: 4,
      },
      768: {
        slidesPerView: 3,
      },
      0: {
        spaceBetween: 10,
        slidesPerView: "auto",
      },
    },
  };

  return (
    <Swiper {...params}>
      {slides.map((slide, index) => (
        <div key={index}>
          <Slide {...slide} />
        </div>
      ))}
    </Swiper>
  );
};
