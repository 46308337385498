import React from "react";

export const TrackingLink = ({
  children,
  href = "/",
  className = "",
  component: Component = null,
}) => {
  const [link, setLink] = React.useState(href);

  React.useEffect(() => {
    const intervalId = setInterval(() => {
      const search = localStorage.getItem("search");

      if (search !== null) {
        setLink(prevLink => `${prevLink}${search}`);

        clearInterval(intervalId);
      }
    }, 100);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  if (Component === null) {
    return (
      <a href={link} className={className} target="_blank" rel="noopener noreferrer">
        {children}
      </a>
    );
  }

  return (
    <Component href={link} className={className} target="_blank" rel="noopener noreferrer">
      {children}
    </Component>
  );
};
