import { createSlice } from "@reduxjs/toolkit";

const quizSlice = createSlice({
  name: "quiz",
  initialState: {
    isOpen: false,
  },
  reducers: {
    setQuiz: (state, action) => ({
      ...state,
      ...action.payload,
    }),
    quizOpen: state => ({
      ...state,
      isOpen: true,
    }),
    quizClose: state => ({
      ...state,
      isOpen: false,
    }),
  },
});

export const { setQuiz } = quizSlice.actions;
export const { quizOpen } = quizSlice.actions;
export const { quizClose } = quizSlice.actions;

export { quizSlice };
