import React from "react";

export const Check = () => (
  <svg
    fill="none"
    height="12"
    viewBox="0 0 16 12"
    width="16"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="m14.9829.00886838c-.1953.02765072-.3773.12441262-.5149.27188262l-9.2122 9.562529-3.81362-3.166c-.3818-.31799-.941191-.25347-1.247523.14286-.306332.39632-.2441779.97699.137627 1.29497l4.439596 3.68679c.35517.2903.86128.2581 1.18093-.0738l9.76709-10.13854c.3596-.35024.3729-.930912.04-1.304196-.1998-.2119886-.4884-.3179716-.777-.27649562z"
      fill="#fff"
    />
  </svg>
);
