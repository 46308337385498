import { KissWinkHeart, Comment, Ring, Location } from "../assets/svg";

export const quiz = [
  {
    question: "The girls of what age are you searching for?",
    answers: ["18-24", "25-35", "35+", "Doesn't matter"],
  },
  {
    question: "Favorite Body type:",
    answers: ["Slim", "Athletic", "Average", "Curvy", "Doesn't matter"],
  },
  {
    question: "The most important thing in your match:",
    answers: [
      "Intelligence",
      "Family value",
      "Good temper",
      "Sense of humor",
      "Physical attraction",
    ],
  },
  {
    question: "Are you ready to come to girl's country?",
    answers: ["Yes, for sure", "No, better she came", "Don’t know yet"],
  },
];

export const answers = [
  {
    name: "Flirting",
    ico: KissWinkHeart,
  },
  {
    name: "Chatting",
    ico: Comment,
  },
  {
    name: "Marriage",
    ico: Ring,
  },
  {
    name: "Flirting",
    ico: Location,
  },
];
