import React from "react";

import { Header, Footer, Loader, Quiz, QuizPopup } from "../components";

export const CategoryPageLayout = ({ children, location }) => (
  <div className="app">
    <React.Suspense fallback={<Loader />}>
      <Header location={location} />
    </React.Suspense>

    <React.Suspense fallback={<Loader />}>{children}</React.Suspense>

    <React.Suspense fallback={<Loader />}>
      <Footer withPadding={true} />
    </React.Suspense>

    <React.Suspense fallback={<Loader />}>
      <Quiz />
    </React.Suspense>

    <QuizPopup />
  </div>
);
