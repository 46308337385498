import React from "react";

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const LastUpdate = props => {
  const now = new Date();

  const month = months[now.getMonth()].slice(0, 3);
  const year = now.getFullYear();

  return (
    <div {...props}>
      Updated for {month} {year}
    </div>
  );
};
