import React, { useState } from "react";
import { Popup } from "../Popup";
import { Question } from "../../assets/svg";

import styles from "./AdvertisingDisclosure.module.scss";

export const AdvertisingDisclosure = () => {
  const [openPopup, setOpenPopup] = useState(false);

  const handleOpen = () => {
    setOpenPopup(!openPopup);
  };

  return (
    <>
      <p className={styles.AdvertisingDisclosure}>
        We receive fees from partners |{" "}
        <button type="button" className={styles.Button} onClick={handleOpen}>
          Advertising Disclosure <Question width="13" height="13" />
        </button>
      </p>
      {openPopup && (
        <Popup title="Advertising Disclosure" onClose={handleOpen}>
          <p>
            Datingadviceme takes the protection of personal user data seriously and complies
            with the applicable data protection regulations. <br /> <br />
            Our website is a free platform that reviews and compares online dating sites. The
            owner of the site receives compensation for advertising third party dating
            services. <br /> <br />
            Our website pages partly make use of cookies. They serve to make our site more
            user-friendly, effective and secure, and are small text files that are stored on
            your computer and browser.
          </p>
        </Popup>
      )}
    </>
  );
};
