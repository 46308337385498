import {
  russian,
  ukrainian,
  china,
  japan,
  slavic,
  asian,
  philippine,
  latin,
  european,
  international,
} from "./girls";
import {
  russianOffers,
  ukrainianOffers,
  asianOffers,
  latinOffers,
  findOffer,
  europeanOffers,
  internationalOffers,
} from "./offers";

const imagePath = nationality => `/images/girls/${nationality}/`;

const constructor = (nationality, offers, girls) =>
  girls.map((girl, index) => {
    const i = index + 1;
    const evenParity = i % 2 === 0;
    const oddOffer = field => findOffer(offers[0])[field];
    const evenOffer = field => findOffer(offers[1])[field];

    const id = `${nationality}-${i}`;
    const image = `${imagePath(nationality)}${i}.webp`;
    const website = evenParity ? oddOffer("name") : evenOffer("name");
    const url = evenParity ? oddOffer("link") : evenOffer("link");

    return {
      id,
      image,
      website,
      url,
      ...girl,
    };
  });

export const girls = [
  // {
  //   nationality: "Japan",
  //   girls: constructor("japan", asianOffers, japan),
  // },
  {
    nationality: "Ukrainian",
    girls: constructor("ukrainian", ukrainianOffers, ukrainian),
  },
  {
    nationality: "Russian",
    girls: constructor("russian", russianOffers, russian),
  },
  // {
  //   nationality: "China",
  //   girls: constructor("china", asianOffers, china),
  // },
  // {
  //   nationality: "Slavic",
  //   girls: constructor("slavic", russianOffers, slavic),
  // },
  {
    nationality: "Asian",
    girls: constructor("asian", asianOffers, asian),
  },
  // {
  //   nationality: "Philippine",
  //   girls: constructor("philippine", asianOffers, philippine),
  // },
  {
    nationality: "Latin",
    girls: constructor("latin", latinOffers, latin),
  },
  {
    nationality: "European",
    girls: constructor("european", europeanOffers, european),
  },
  {
    nationality: "International",
    girls: constructor("international", internationalOffers, international),
  },
];
