import React from "react";
import { TrackingLink } from "../TrackingLink";
import { Button2 } from "../Buttons";
import { useWindowSize } from "../../hooks";

import styles from "./ScrollVisitSite.module.scss";

export const ScrollVisitSite = ({ link }) => {
  const { width } = useWindowSize();

  return width < 768 ? (
    <div className={styles.ScrollVisitSite}>
      <TrackingLink href={link} className={styles.Button} component={Button2}>
        Visit Site
      </TrackingLink>
    </div>
  ) : null;
};
