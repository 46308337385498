import React from "react";
import { CircularProgressbar as Progressbar, buildStyles } from "react-circular-progressbar";

const stylesProgressbar = (styles = {}) =>
  buildStyles({
    rotation: 1 / 2 + 1 / 8,
    trailColor: "#eee",
    pathColor: "#e64d84",
    textColor: "#f88",
    textSize: "28px",
    ...styles,
  });

export const CircularProgressbar = ({ value, text, styles }) => (
  <Progressbar
    value={value}
    text={text}
    circleRatio={0.75}
    strokeWidth={6}
    styles={stylesProgressbar(styles)}
  />
);
