import React, { useEffect, useRef, useState } from "react";
import { quiz } from "../../../data";
import { Loader } from "../../Loader";
import { CircularProgressbar } from "../../CircularProgressbar";
import { findOffer } from "../../../data";

import styles from "./QuizForm.module.scss";

export const QuizForm = () => {
  const refButton = useRef();
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const countQuestions = quiz.length;
  const quizItem = quiz[currentQuestion];
  const [link, setLink] = useState("");

  const offer = findOffer("DateAsianWoman");

  const progressBarValue = 100 * ((currentQuestion + 1) / countQuestions);
  const progressBarText = `${currentQuestion + 1} / ${countQuestions}`;

  const handleNextQuestion = () => {
    setCurrentQuestion(currentQuestion + 1);
  };

  useEffect(() => {
    if (currentQuestion + 1 > countQuestions) {
      const gclid = localStorage.getItem("gclid");
      const utmTerm = localStorage.getItem("utm_term");
      const utmMedium = localStorage.getItem("utm_medium");

      setLink(`${offer.link}?gclid=${gclid}&keywords=${utmTerm}&placement=${utmMedium}`);

      setTimeout(() => {
        refButton.current.click();
      }, 2000);
    }
  }, [currentQuestion, countQuestions, offer]);

  return currentQuestion + 1 > countQuestions ? (
    <>
      <Loader />
      <a href={link} ref={refButton}>
        {" "}
      </a>
    </>
  ) : (
    <>
      <div className={styles.Quiz__CircularProgressbar}>
        <CircularProgressbar
          value={progressBarValue}
          text={progressBarText}
          styles={{ textSize: "20px" }}
        />
      </div>
      <div className={styles.Quiz__Question}>{quizItem.question}</div>
      <div className={styles.Quiz__Answers}>
        {quizItem.answers.map((answer, index) => (
          <div key={index} className={styles.Quiz__Answers__Item}>
            <button type="button" className={styles.Button} onClick={handleNextQuestion}>
              {answer}
            </button>
          </div>
        ))}
      </div>
    </>
  );
};
