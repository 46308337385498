import { createSlice } from "@reduxjs/toolkit";

const init = {
  active: false,
  image: "",
  style: {
    width: 0,
    height: 0,
    left: 0,
    top: 0,
  },
};

const favoriteGirlSlice = createSlice({
  name: "favoriteGirl",
  initialState: init,
  reducers: {
    setFavoriteGirl: (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    setFavoriteGirlStyle: (state, action) => {
      return {
        ...state,
        style: { ...state.style, ...action.payload },
      };
    },
    setFavoriteGirlActive: (state, action) => {
      return {
        ...state,
        active: action.payload,
      };
    },
    resetFavoriteGirl: () => {
      return init;
    },
  },
});

export const { setFavoriteGirl } = favoriteGirlSlice.actions;
export const { resetFavoriteGirl } = favoriteGirlSlice.actions;
export const { setFavoriteGirlStyle } = favoriteGirlSlice.actions;
export const { setFavoriteGirlActive } = favoriteGirlSlice.actions;

export { favoriteGirlSlice };
