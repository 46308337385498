import React from "react";

import {
  Header,
  Footer,
  Main,
  MainScreen,
  Loader,
  SliderOffers,
  QuizPopup,
} from "../components";

export const HomePageLayout = ({ children, location }) => {
  return (
    <div className="app">
      <React.Suspense fallback={<Loader />}>
        <Header location={location} />
      </React.Suspense>

      <MainScreen />

      <SliderOffers />

      <Main>
        <React.Suspense fallback={<Loader />}>{children}</React.Suspense>
      </Main>

      <React.Suspense fallback={<Loader />}>
        <Footer />
      </React.Suspense>

      <QuizPopup />
    </div>
  );
};
