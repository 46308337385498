import React from "react";
import { Review } from "./Review";
import { reviews } from "../../data";

import styles from "./Reviews.module.scss";

export const Reviews = ({ title = "" }) => (
  <div className={styles.Reviews}>
    {title !== "" && <h2 className="title-section">{title}</h2>}
    <div className={styles.Row}>
      {reviews.map((review, index) => (
        <div key={index} className={styles.Col}>
          <Review {...review} />
        </div>
      ))}
    </div>
  </div>
);
