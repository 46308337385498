import React, { useEffect, useRef } from "react";
import { Times } from "../../assets/svg";

import styles from "./Popup.module.scss";

export const Popup = ({ title, children, onClose }) => {
  const node = useRef();

  useEffect(() => {
    document.addEventListener("mousedown", handleClick);
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  });

  const handleClick = e => {
    if (node.current.contains(e.target)) {
      return;
    }
    onClose();
  };

  return (
    <>
      <div className={styles.Bg} onClick={onClose}></div>
      <div className={styles.PopupWrapper}>
        <div className={styles.PopupContainer}>
          <div className={styles.PopupContent}>
            <div className={styles.PopupCenter} ref={node}>
              <button type="button" className={styles.ButtonClose} onClick={onClose}>
                <Times width="15" height="15" />
              </button>
              <div className={styles.Title}>{title}</div>
              <div className={styles.Body}>{children}</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
