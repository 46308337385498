import React from "react";
import { Route } from "react-router-dom";
import { DefaultLayout } from "./layouts";
import { routes } from "./pages";

const RouteWrapper = ({ component: Component, layout: Layout, ...rest }) => (
  <Route
    {...rest}
    render={props => (
      <Layout {...props}>
        <Component {...props} />
      </Layout>
    )}
  />
);

export const Routes = () => {
  return routes.map(({ exact, layout = DefaultLayout, path, component }) => (
    <RouteWrapper key={path} exact={exact} path={path} layout={layout} component={component} />
  ));
};
