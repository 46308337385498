import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { favoriteSlice, favoriteGirlSlice, quizSlice } from "./features";

const rootReducer = combineReducers({
  favorite: favoriteSlice.reducer,
  favoriteGirl: favoriteGirlSlice.reducer,
  quiz: quizSlice.reducer,
});

const store = configureStore({
  reducer: rootReducer,
  devTools: process.env.NODE_ENV !== "production",
});

export { store };
