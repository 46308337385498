import React from "react";
import { Link } from "react-router-dom";
import { Button2 } from "../Buttons";
import { TrackingLink } from "../TrackingLink";

import styles from "./Reviews.module.scss";

export const Review = ({ author, image, review, offer }) => (
  <div className={styles.Review}>
    <div className={styles.Review__Img}>
      <div className={styles.Review__Image} style={{ backgroundImage: `url(${image})` }} />
    </div>
    <div className={styles.Review__Content}>
      <div className={styles.Review__Name}>{author}</div>
      <div className={styles.Review__Text}>{review}</div>
      <div className={styles.Review__VisitSite}>
        <TrackingLink href={offer.link} component={Button2}>
          Visit Site
        </TrackingLink>
      </div>
      <div className={styles.Review__ReadReview}>
        <Link to={offer.review}>Read Review</Link>
      </div>
    </div>
  </div>
);
