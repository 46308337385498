import React, { useState, useRef } from "react";
import { useSelector } from "react-redux";
import FadeIn from "react-fade-in";
import { useOnClickOutside } from "../../../hooks";
import { ButtonFavoriteInversion } from "../../Buttons";
import { FavoriteGirls } from "./FavoriteGirls";

import styles from "./Favorite.module.scss";

export const Favorite = () => {
  const ref = useRef();
  const [isOpen, setIsOpen] = useState(false);
  const favorite = useSelector(state => state.favorite);
  const count = favorite.length;
  const notEmpty = count !== 0;

  const handleOpen = () => {
    setIsOpen(!isOpen);
  };

  useOnClickOutside(ref, () => {
    setIsOpen(false);
  });

  return (
    <div className={styles.Favorite} ref={ref}>
      <div className={styles.ButtonGroup}>
        {notEmpty && <div className={styles.Counter}>{count}</div>}
        <ButtonFavoriteInversion className={notEmpty ? "active" : ""} onClick={handleOpen} />
      </div>
      {isOpen && (
        <FadeIn>
          <FavoriteGirls girlsIds={favorite} />
        </FadeIn>
      )}
    </div>
  );
};
