import React from "react";
import { useSelector, useDispatch } from "react-redux";

import { Popup } from "../Popup";
import { QuizForm } from "../Quiz/QuizForm";
import { quizClose } from "../../features";

export const QuizPopup = () => {
  const dispatch = useDispatch();
  const quiz = useSelector(state => state.quiz);

  const handleClose = () => {
    dispatch(quizClose());
  };

  return quiz.isOpen ? (
    <Popup onClose={handleClose}>
      <QuizForm />
    </Popup>
  ) : null;
};
