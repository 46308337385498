import React from "react";

import { TrackingLink } from "../TrackingLink";
import styles from "./Preland.module.scss";
import { PATH_REDIRECT } from "../../configs";

export const Preland = ({
  imgSrc,
  imgAlt = "Beautiful girl",
  title = "Attention!",
  secondTitle = "Find Your Soulmate!",
  description = "A Special Selection for Special People. We've Already Prepared a List of the Best Dating Services.",
  buttonText = "Match My Style",
  offerId = PATH_REDIRECT + "/gochatty",
}) => {
  return (
    <div className={styles.container}>
      <img src={imgSrc} alt={imgAlt} className={styles.image} />
      <div className={styles.adContainer}>
        <div className={styles.adText}>
          <h1 className={styles.adH1}>{title}</h1>
          <h2 className={styles.adH2}>{secondTitle}</h2>
          <p className={styles.description}>{description}</p>
        </div>
        <TrackingLink href={offerId}>
          <span className={styles.button}>
            {buttonText}{" "}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="15"
              height="15"
              viewBox="0 0 600 600">
              <path
                fill="#FFF"
                d="m7.7,404.6c0,0 115.2,129.7 138.2,182.68l99,0c41.5-126.7 202.7-429.1 340.92-535.1c28.6-36.8-43.3-52-101.35-27.62-87.5,36.7-252.5,317.2-283.3,384.64-43.7,11.5-89.8-73.7-89.84-73.7z"
              />
            </svg>
          </span>
        </TrackingLink>
      </div>
    </div>
  );
};
