export const russian = [
  {
    name: "Marisha",
    age: 19,
    location: "Russian, Saint Petersburg",
  },
  {
    name: "Galina",
    age: 22,
    location: "Russian, Kazan",
  },
  {
    name: "Katina",
    age: 30,
    location: "Russian, Moscow",
  },
  {
    name: "Karine",
    age: 32,
    location: "Russian, Volgograd",
  },
  {
    name: "Katy",
    age: 18,
    location: "Russian, Rostov-on-Don",
  },
  {
    name: "Anna",
    age: 28,
    location: "Russian, Chelyabinsk",
  },
  {
    name: "Yelena",
    age: 32,
    location: "Russian, Khabarovsk",
  },
  {
    name: "Natasha",
    age: 36,
    location: "Russian, Perm",
  },
  {
    name: "Lada",
    age: 25,
    location: "Russian, Samara",
  },
  {
    name: "Khristina",
    age: 31,
    location: "Russian, Krasnodar",
  },
];
