import { findOffer } from "./offers";

const PATH_IMAGE = "/images/reviews/";

export const reviews = [
  {
    author: "Nicholas",
    image: PATH_IMAGE + "review1.webp",
    review:
      "I have never thought that it's possible to find a good man who will make me smile again and open a new stage of my life. I wanted to say that best things that we are doing are unexpected and spontaneous. So, I met Anton on KissRussianBeauty site and I am very happy now. I wish all people who were looking for their better half would find it on this website.",
    offer: findOffer("KissRussianBeauty"),
  },
  {
    author: "Diana and Travis",
    image: PATH_IMAGE + "review2.webp",
    review:
      "I want to write a story about our meeting with Travis, it was amazing time together. We talked about everything and I enjoyed every minute in his company. He is kind, funny person, we have a lot in common and I am sure it was not our last meeting, because I have positive impressions about him and would like to know him better)",
    offer: findOffer("KissRussianBeauty"),
  },
  {
    author: "Simon and Rina",
    image: PATH_IMAGE + "review3.webp",
    review:
      "DateAsianWoman changed my life forever. Of all my wildest dreams, I never thought that I would one day meet my soulmate over the internet, but I did. I still can't believe it, but here I am. I am happy and preparing to marry the love of my life. I want to say a very big THANK YOU to everyone here for making this possible.",
    offer: findOffer("DateAsianWoman"),
  },
];
