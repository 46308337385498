import { createSlice } from "@reduxjs/toolkit";

const favoriteSlice = createSlice({
  name: "favorite",
  initialState: [],
  reducers: {
    addFavorite: (state, action) => {
      state = [...state, action.payload];
      return state;
    },
    removeFavorite: (state, action) => {
      state = state.filter(item => item !== action.payload);
      return state;
    },
    resetFavorite: state => {
      state = [];
      return state;
    },
    getFavorite: state => {
      return state;
    },
  },
});

export const { addFavorite } = favoriteSlice.actions;
export const { removeFavorite } = favoriteSlice.actions;
export const { resetFavorite } = favoriteSlice.actions;
export const { getFavorite } = favoriteSlice.actions;

export { favoriteSlice };
