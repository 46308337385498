import React from "react";

import styles from "./Nav.module.scss";

export const Nav = ({ nav = [], currentCategory = 0, onChange }) => (
  <div className={styles.Nav}>
    <div className="container">
      <ul className={styles.Nav__List}>
        {nav.map((item, index) => (
          <li key={index}>
            <button
              type="button"
              className={currentCategory === index ? styles.Active : ""}
              onClick={() => onChange(index)}>
              {item}
            </button>
          </li>
        ))}
      </ul>
    </div>
  </div>
);
