export const latin = [
  {
    name: "Camila",
    age: 22,
    location: "Brazil, São Paulo",
  },
  {
    name: "Victoria",
    age: 32,
    location: "Mexico, Mexico City",
  },
  {
    name: "Aurora",
    age: 23,
    location: "Peru, Lima",
  },
  {
    name: "Rose",
    age: 35,
    location: "Colombia, Bogotá",
  },
  {
    name: "Diana",
    age: 20,
    location: "Brazil, Fortaleza",
  },
  {
    name: "Laura",
    age: 24,
    location: "Ecuador, Guayaquil",
  },
];
