import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Location, Website, Thumbs } from "../../assets/svg";
import { Button2, ButtonFavorite, ButtonShowMoreDown } from "../Buttons";
import { TrackingLink } from "../TrackingLink";
import { addFavorite, removeFavorite, setFavoriteGirl } from "../../features";

import "./Courousel.scss";

export const Slide = ({ image, name, age, location, website, url, id }) => {
  const imageRef = useRef();
  const dispatch = useDispatch();
  const [addToFavorite, setAddToFavorite] = useState(false);
  const favorite = useSelector(state => state.favorite);
  const isSelect = favorite.includes(id);

  useEffect(() => {
    if (isSelect) {
      setAddToFavorite(true);

      const { width, height, x, y } = imageRef.current.getBoundingClientRect();

      dispatch(
        setFavoriteGirl({
          active: true,
          image: image,
          style: {
            width: width,
            height: height,
            left: x,
            top: y,
          },
        }),
      );
    }
    // eslint-disable-next-line
  }, [isSelect]);

  useEffect(() => {
    if (addToFavorite) {
      setTimeout(() => {
        setAddToFavorite(false);
      }, 1000);
    }
  }, [addToFavorite]);

  const handleFavorite = () => {
    if (isSelect) {
      dispatch(removeFavorite(id));
    } else {
      dispatch(addFavorite(id));
    }
  };

  const handleDetails = () => {
    //
  };

  return (
    <div className="slide-item">
      <div className="slide-item__image">
        <TrackingLink href={url}>
          <div
            className="slide-item__image-img"
            ref={imageRef}
            style={{ backgroundImage: `url(${image})` }}></div>
        </TrackingLink>
        <div className={`your-choice ${isSelect ? "active" : ""}`}>
          <Thumbs width="16" height="16" /> your choice
        </div>
        <TrackingLink
          href={url}
          className="btn__show-more"
          onClick={handleDetails}
          component={ButtonShowMoreDown}
        />
        <TrackingLink href={url} className="btn__find-me" component={Button2}>
          Find Me
        </TrackingLink>
        <ButtonFavorite
          className={`btn__favorite ${isSelect ? "active" : ""}`}
          onClick={handleFavorite}
        />
      </div>
      <div className="slide-item__info">
        <div className="slide-item__name">
          {name}, {age}
        </div>
        <div className="slide-item__group">
          <div className="slide-item__group__name">
            <Location width="20" height="20" />
          </div>
          <div className="slide-item__group__value">{location}</div>
        </div>
        <div className="slide-item__group">
          <div className="slide-item__group__name">
            <Website width="18" height="18" />
          </div>
          <div className="slide-item__group__value">
            <TrackingLink href={url}>{website}</TrackingLink>
          </div>
        </div>
      </div>
    </div>
  );
};
