import React from "react";
import { useDispatch } from "react-redux";
import { useWindowSize } from "../../../hooks";
import { Button3, ButtonDelete } from "../../Buttons";
import { TrackingLink } from "../../TrackingLink";
import { girls } from "../../../data";
import { removeFavorite } from "../../../features";

import styles from "./Favorite.module.scss";

const styleButton = {
  width: "100%",
  textAlign: "center",
};

const Girl = ({ id, image, name, age, url }) => {
  const dispatch = useDispatch();

  const handleRemove = event => {
    event.preventDefault();

    dispatch(removeFavorite(id));
  };

  return (
    <div className={styles.Girl}>
      <div className={styles.Girl__Row}>
        <div className={styles.Girl__Image}>
          <img src={image} alt={`${name}, ${age}`} />
        </div>
        <div className={styles.Girl__Content}>
          <div className={styles.Girl__Name}>
            {name}, {age}
          </div>
          <div className={styles.Girl__Actions}>
            <div className={styles.Girl__Actions__Col1}>
              <TrackingLink href={url} style={styleButton} component={Button3}>
                Find Me
              </TrackingLink>
            </div>
            <div className={styles.Girl__Actions__Col2}>
              <ButtonDelete onClick={handleRemove} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Girls = ({ girlsData = [] }) => (
  <div className={styles.FavoriteGirls__List}>
    {girlsData.map((girl, index) => (
      <Girl key={index} {...girl} />
    ))}
  </div>
);

const NotFound = () => (
  <div className={styles.NotFound}>
    <div>Not Found</div>
  </div>
);

const Scrollbar = ({ children }) => {
  const sizeWindow = useWindowSize();
  const height = sizeWindow.height - 200 || 100;

  return (
    <div style={{ overflowY: "auto", maxHeight: height, height: "auto" }}>{children}</div>
  );
};

export const FavoriteGirls = ({ girlsIds = [] }) => {
  const girlsData = girls
    .map(item => item.girls)
    .reduce((acc, item) => [...acc, ...item], [])
    .filter(({ id }) => girlsIds.includes(id))
    .map(({ id, image, name, age, url }) => ({ id, image, name, age, url }));

  return (
    <div className={styles.FavoriteGirls}>
      <div className={styles.FavoriteGirls__Title}>You've added to favorites:</div>
      <Scrollbar>
        {girlsData.length === 0 ? <NotFound /> : <Girls girlsData={girlsData} />}
      </Scrollbar>
    </div>
  );
};
