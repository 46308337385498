import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { navsHeader } from "../../../data";

import styles from "./Menu.module.scss";

export const Menu = ({ location: { pathname } }) => {
  const [openMenu, setOpenMenu] = useState(false);
  const isActive = url => (url === pathname ? styles.Active : "");

  useEffect(() => {
    setOpenMenu(false);
  }, [pathname]);

  const handleOpen = () => {
    setOpenMenu(!openMenu);
  };

  return (
    <>
      <button
        type="button"
        className={`${styles.OpenMenu} ${openMenu ? styles.Open : ""}`}
        onClick={handleOpen}>
        <span></span>
        <span></span>
        <span></span>
      </button>
      <ul className={`${styles.Menu} ${openMenu ? styles.Open : ""}`}>
        {navsHeader.map(({ name, url }) => (
          <li key={url}>
            <Link to={url} className={isActive(url)}>
              {name}
            </Link>
          </li>
        ))}
      </ul>
    </>
  );
};
