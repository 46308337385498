import React from "react";
import Lottie from "lottie-react-web";

import animation from "../../assets/loader/data.json";

import "./Loader.scss";

export const Loader = ({ loading }) => {
  if (loading === true) {
    return (
      <div className="loader">
        <Lottie
          width="80px"
          height="80px"
          options={{
            animationData: animation,
          }}
        />
      </div>
    );
  }

  return null;
};

Loader.defaultProps = {
  loading: true,
  wrapper: false,
};
