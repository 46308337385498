import React from "react";

import { Header, Footer, Main, Loader } from "../components";

export const DefaultLayout = ({ children, location }) => {
  return (
    <div className="app">
      <React.Suspense fallback={<Loader />}>
        <Header location={location} />
      </React.Suspense>

      <Main>
        <React.Suspense fallback={<Loader />}>{children}</React.Suspense>
      </Main>

      <React.Suspense fallback={<Loader />}>
        <Footer />
      </React.Suspense>
    </div>
  );
};
