import { nationalities } from "../../data";
import style from "./Nationaliti.module.scss";

export const Nationaliti = ({ selected = [], ethnicTitle = "", ethnicLink = "" }) => {
  const selectedOffers =
    selected.length === 0
      ? nationalities
      : selected.reduce((acc, name) => {
          nationalities.forEach(site => {
            if (name === site.name) {
              acc.push(site);
            }
          });

          return acc;
        }, []);

  return (
    <div className={style.NationalitySection}>
      <div className="container">
        <h2>Learn more about</h2>
        <div className={style.NationalityContainer}>
          {selectedOffers.map((el, index) => (
            <a className={style.Item} key={index} href={el.review}>
              {el.title}
            </a>
          ))}
          {ethnicTitle !== "" && (
            <a className={style.Item} href={ethnicLink}>
              {ethnicTitle}
            </a>
          )}
        </div>
      </div>
    </div>
  );
};
