import React, { useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { useOnClickOutside } from "../../hooks";
import { answers } from "../../data";
import { quizOpen } from "../../features";

import styles from "./Quiz.module.scss";

export const Quiz = () => {
  const dispatch = useDispatch();
  const ref = useRef();
  const [show, setShow] = useState(false);

  const handleShow = () => {
    setShow(!show);
  };

  const handleOpen = () => {
    dispatch(quizOpen());
  };

  useOnClickOutside(ref, () => {
    setShow(false);
  });

  return (
    <>
      <div
        className={show ? `${styles.QuizBanner} ${styles.Open}` : styles.QuizBanner}
        ref={ref}>
        <button type="button" className={styles.QuizBanner__Title} onClick={handleShow}>
          I'm looking for...
        </button>
        <div className={styles.QuizBanner__Content}>
          {answers.map(({ name, ico: Ico }, index) => (
            <div key={index} className={styles.QuizBanner__Content__Col}>
              <button
                type="button"
                className={styles.QuizBanner__Content__Item}
                onClick={handleOpen}>
                <div className={styles.QuizBanner__Content__Item__Ico}>
                  <Ico width="30" height="30" />
                </div>
                <div className={styles.QuizBanner__Content__Item__Name}>{name}</div>
              </button>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
