import React from "react";
import {
  HomePageLayout,
  CategoryPageLayout,
  ReviewLayout,
  NationalityLayout,
  DefaultLayout,
  PmaxQuizPageLayout,
  PrelandLayout,
} from "../layouts";
import { offers, nationalities } from "../data";

const Home = React.lazy(() => import("./home"));
// const Oriental = React.lazy(() => import("./oriental"));
const Ukrainian = React.lazy(() => import("./ukrainian"));
const Russian = React.lazy(() => import("./russian"));
const Asian = React.lazy(() => import("./asian"));
const Latin = React.lazy(() => import("./latin"));
const Eurоpean = React.lazy(() => import("./eurоpean"));
const International = React.lazy(() => import("./international"));
const PmaxQuiz = React.lazy(() => import("./quiz/pmax"));
const Lnd1_1 = React.lazy(() => import("./lnd1_1"));
const Lnd1_2 = React.lazy(() => import("./lnd1_2"));
const Lnd1_3 = React.lazy(() => import("./lnd1_3"));

export const routes = [
  {
    path: "/",
    exact: true,
    name: "Home",
    component: Home,
    layout: HomePageLayout,
  },
  // {
  //   path: "/oriental",
  //   name: "Oriental",
  //   component: Oriental,
  //   layout: CategoryPageLayout,
  // },
  {
    path: "/ukrainian",
    name: "Ukrainian",
    component: Ukrainian,
    layout: CategoryPageLayout,
  },
  {
    path: "/russian",
    name: "Russian",
    component: Russian,
    layout: CategoryPageLayout,
  },
  {
    path: "/asian",
    name: "Asian",
    component: Asian,
    layout: CategoryPageLayout,
  },
  {
    path: "/latin",
    name: "Latin",
    component: Latin,
    layout: CategoryPageLayout,
  },
  {
    path: "/eurоpean",
    name: "Eurоpean",
    component: Eurоpean,
    layout: CategoryPageLayout,
  },
  {
    path: "/international",
    name: "International",
    component: International,
    layout: CategoryPageLayout,
  },
  {
    path: "/quiz/pmax",
    name: "PmaxQuiz",
    component: PmaxQuiz,
    layout: CategoryPageLayout,
  },
  {
    path: "/lnd1_1",
    name: "Lnd1_1",
    component: Lnd1_1,
    layout: PrelandLayout,
  },
  {
    path: "/lnd1_2",
    name: "Lnd1_2",
    component: Lnd1_2,
    layout: PrelandLayout,
  },
  {
    path: "/lnd1_3",
    name: "Lnd1_3",
    component: Lnd1_3,
    layout: PrelandLayout,
  },
  ...offers.map(({ name, review, component: Component }) => ({
    name,
    path: review,
    component: Component,
    layout: ReviewLayout,
  })),
  ...nationalities.map(({ name, review, component: Component }) => ({
    name,
    path: review,
    component: Component,
    layout: NationalityLayout,
  })),
];
